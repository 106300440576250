
import { Prop, Component, Vue } from 'vue-property-decorator';
import { DASHBOARD_URL } from '../config/secrets';

@Component({})
export default class BaseRowContent extends Vue {
    dashboardUrl = DASHBOARD_URL;

    @Prop() content!: {
        tag: string;
        image: string;
        title: string;
        description: string;
        list: string[];
    };

    @Prop() imgAlign!: string;
}
